/**
 * A data structure indicating how facets should be grouped
 * @typedef {Object} FacetGrouping
 * @property {string} key
 * @property {string} label
 * @property {Array.<String>} An array of facet keys indicating that those facets should be displayed under one label
 */

import $ from 'jquery';

import ChairishState from 'chairisher/util/state';
import ChairishUri from 'chairisher/util/uri';
import FacetBuilderView from 'chairisher/view/search/facetbuilder';
import FacetSummaryView from 'chairisher/view/search/facetsummary';
import FormUtils from 'chairisher/util/form';
import HistoryUtils from 'chairisher/util/history';
import KeyUtils from 'chairisher/util/key';
import LocationPopoverComponent from 'chairisher/component/geo/locationpopover';
import MetaUtils from 'chairisher/util/meta';
import ShopAdAnalytics from 'chairisher/view/promotedlistings/shopad';
import ViewportUtil from 'chairisher/util/viewport';

import { isCollection, isShop } from 'chairisher/context/collection';
import { setProductIdToProductObjMap } from 'chairisher/context/product';
import {
    FacetTagNames,
    getCustomRangeFieldNames,
    getDimensionFieldNames,
    getPriceFacetFieldNames,
    getSearchValidationEndpointUrl,
    getShippingOptions,
    hasSearchResults,
} from 'chairisher/context/search';
import { toCentimeters, toInches, DIMENSION_UNITS } from 'chairisher/util/i18n';
import { isMinLargeDesktop } from 'chairisher/util/mediaquery';
import { getShippableCountryByCode } from 'chairisher/context/location';

const { LocationPopover, LocationPopoverEvents } = LocationPopoverComponent;

/**
 * Keys to skip when examining an instances' state
 */
const FACET_KEYS_TO_SKIP = ['location', 'page', 'page_size', 'sort'];

const CUSTOM_FACET_INPUT_SELECTOR = '.js-custom-facet-input';
const CUSTOM_FACET_SUBMIT_SELECTOR = '.js-custom-facet-submit';
const CUSTOM_FACET_VALUE_SELECTOR = '.js-custom-facet-value';
const DIMENSION_UNIT_INPUT_SELECTOR = '.js-dimension-unit-input';
const FACET_CHOICES_SELECTOR = '.js-facet-choices';
const FACET_CHOICE_GROUP_SELECTOR = '.js-facet-choice-group';
const FACET_GROUP_LABEL_SELECTOR = '.js-facet-choice-group-label';
const FACET_INPUT_SELECTOR = '.js-facet-input';
const FACET_SELECTOR = '.js-facet';
const FACET_TITLE_SELECTOR = '.js-facet-title';
const MAX_RANGE_SELECTOR = '.js-range-max';
const MIN_RANGE_SELECTOR = '.js-range-min';
const SEARCH_FORM_SELECTOR = '#js-search-form';

/**
 * Returns the value of the facet's custom range, if any.
 *
 * @param {jQuery} $rangeContainer An element that contains '.js-range-min' and '.js-range-max' inputs.
 * @return {string} The value e.g. '250-1000', or an empty string if the inputs are invalid or empty.
 */
function getCustomRangeValue($rangeContainer) {
    const isCentimeters = $rangeContainer
        .siblings('.js-dimension-unit')
        .find(`${DIMENSION_UNIT_INPUT_SELECTOR}[value="cm"]`)
        .is(':checked');

    const coerceValue = (valuePart) => {
        if (Number.isFinite(valuePart) && valuePart > 0) {
            return isCentimeters ? toInches(valuePart) : valuePart;
        }
        return '';
    };

    let value = '';
    let minValue = parseInt($rangeContainer.find(MIN_RANGE_SELECTOR).val(), 10) || 0;
    let maxValue = parseInt($rangeContainer.find(MAX_RANGE_SELECTOR).val(), 10) || Infinity;

    // Make sure minValue <= maxValue, and that both are positive finite numbers.
    minValue = coerceValue(Math.min(minValue, maxValue));
    maxValue = coerceValue(Math.max(minValue, maxValue));

    if (minValue || maxValue) {
        value = `${minValue}-${maxValue}`;
    }
    return value;
}

let mostRecentXhr = null;

/**
 * View that assists in filtering and sorting product searches
 */
class SearchView {
    constructor({ defaultPageSize, dimensionData, facets, facetGroupings, searchErrors, isAccordion }) {
        this.$searchForm = $(SEARCH_FORM_SELECTOR);
        this.facetBuilder = new FacetBuilderView();
        this._facetGroupings = facetGroupings;
        this._facetKeyValuetoCompatibleFacetsMap = {};
        this._facetKeytoCompatibleFacetKeyMap = {};
        this.facetSummaryView = new FacetSummaryView();
        this._isAccordion = isAccordion;

        /**
         * @type {Array.<FacetChoice>}
         */
        this.facets = facets;

        /**
         * @type {Array.<string>}
         */
        this.filterAndSortNames = [];

        /**
         * A list of facets that compete with dimension filters for visibility
         *
         * @type {Array.<string>}
         */
        this.competingFacetsDimensions = dimensionData.competing_facets || [];

        /**
         * The facet key after which dimension filters should be inserted
         *
         * @type {string}
         */
        this.dimensionGroupDisplayAfterKey = dimensionData.display_key;

        /**
         * @type {Array.<DimensionGroup>}
         */
        this.dimensionGroups = dimensionData.groups || [];

        /**
         * The index in which dimensions filters should appear amongst search facets
         */
        this.dimensionGroupDisplayIndex = -1;

        /**
         * The default number of items to display per page
         *
         * @type {number}
         */
        this.defaultPageSize = defaultPageSize;

        /**
         * True indicates the form has changes that have not yet been applied
         */
        this._isDirty = false;

        this._state = new ChairishState({ filterAndSortNames: this.filterAndSortNames });
        this._ensureState();

        this.setFacets(facets);
        this.buildFacets();
        this.bindFacets();
        this.showAllFacets();
        this.updateDimensionFacet();
        this.bindRefinementVisibility();
        this.updateFormSelections();
        this.displayFacetTags();
        ShopAdAnalytics.initializeShopAdClickListenersAndImpressions();
        this.updateErrors(searchErrors);
    }

    /**
     * Binds accordion behavior for facets
     */
    bindRefinementVisibility() {
        this.getSearchForm().on('click', FACET_TITLE_SELECTOR, (e) => {
            const $this = $(e.currentTarget);
            const $choices = $this.siblings(FACET_CHOICES_SELECTOR);
            const isExpanded = $choices.attr('aria-expanded') === 'true';

            if (this._isAccordion) {
                const $facets = $('.js-facet').not($this);

                $facets.each((i) => {
                    const $el = $facets.eq(i);
                    this.toggleRefinementVisibility(
                        $el.children(FACET_TITLE_SELECTOR),
                        $el.children(FACET_CHOICES_SELECTOR),
                        false,
                    );
                });
            }
            this.toggleRefinementVisibility($this, $choices, !isExpanded);
        });
    }

    bindFacets() {
        const $searchForm = this.getSearchForm();
        ['categories', 'pillow_size', 'rug_size'].forEach((keyToBind) => {
            const $facet = $searchForm.find(`[data-key="${keyToBind}"]`);
            const id = `js-facet-${keyToBind}`;

            $facet.attr({ id });

            // decorate the facet to be collapsible
            const $facetGroups = $facet.find(FACET_CHOICE_GROUP_SELECTOR);
            if (this._isAccordion) {
                $facetGroups
                    .find(FACET_GROUP_LABEL_SELECTOR)
                    .add($(FACET_TITLE_SELECTOR))
                    .attr({ 'aria-expanded': false });
            } else {
                $facetGroups.find(FACET_GROUP_LABEL_SELECTOR).attr({ 'aria-expanded': false });
                $(FACET_TITLE_SELECTOR).attr({ 'aria-expanded': true });
            }
            $facetGroups.find(FACET_CHOICES_SELECTOR).addClass('collapse');

            // Handles toggling refinement visibility after a facet click event.
            const handleToggleClick = (e, shouldOnlyExpand) => {
                const $facetGroupLabel = $(e.currentTarget).parent();
                const $choices = $facetGroupLabel.siblings(FACET_CHOICES_SELECTOR);
                const isExpanded = $choices.attr('aria-expanded') === 'true';
                if (shouldOnlyExpand) {
                    if (!isExpanded) {
                        this.toggleRefinementVisibility($facetGroupLabel, $choices, true);
                    }
                } else {
                    this.toggleRefinementVisibility($facetGroupLabel, $choices, !isExpanded);
                }
            };

            // Handle checkbox or title click
            $facet.on('click', `${FACET_GROUP_LABEL_SELECTOR} .js-cicon, .js-facet-choice-group-title`, (e) => {
                handleToggleClick(e, true);
            });

            // Handle +- icon click. Prevents default action of toggling checkbox.
            $facet.on('click', `${FACET_GROUP_LABEL_SELECTOR} .js-group-toggle`, (e) => {
                e.preventDefault();
                handleToggleClick(e, false);
            });

            // Handles click inbetween checkbox and +-. Prevents default action of toggling checkbox.
            $facet.on('click', `${FACET_GROUP_LABEL_SELECTOR} .js-empty-space`, (e) => {
                e.preventDefault();
            });
        });

        /**
         * Submit all custom range inputs in the facet.
         * For example the dimension facet may have width/height/depth choices,
         * this submits them all when the user individually submits any of them.
         */
        const handleSubmitRangeEvent = ({ currentTarget }) => {
            const $facetChoices = $(currentTarget).closest(FACET_CHOICES_SELECTOR);
            $facetChoices.find('.js-facet-choice').each((_, el) => {
                const $facetChoice = $(el);
                this.submitCustomRange($facetChoice, $facetChoice.closest('[data-key]').data('key'));
            });
        };

        // Bind custom ranges, e.g. price & dimension inputs
        $searchForm.on('click', CUSTOM_FACET_SUBMIT_SELECTOR, (e) => {
            handleSubmitRangeEvent(e);
        });

        $searchForm.on('focusin focusout', CUSTOM_FACET_INPUT_SELECTOR, ({ currentTarget, type }) => {
            // design calls for applying a border to all inputs in the choice and all buttons in the facet on focus.
            const $facetChoice = $(currentTarget).closest('.js-facet-choice');
            const shouldApplyFocused = type === 'focusin';
            $facetChoice.find('.js-custom-facet-input-wrapper').toggleClass('focused', shouldApplyFocused);
            $facetChoice.parent().find('.js-custom-facet-submit').toggleClass('focused', shouldApplyFocused);
        });

        $searchForm.on('keypress', CUSTOM_FACET_INPUT_SELECTOR, (e) => {
            if (!KeyUtils.isNumeric(e.which)) {
                // Prevent non-numeric input except tab
                e.preventDefault();
                if (KeyUtils.isEnter(e.which)) {
                    // Can't use the default action to submit since the saved search form wraps this input.
                    handleSubmitRangeEvent(e);
                }
            }
        });

        // Bind price facet
        getPriceFacetFieldNames().forEach((priceKey) => {
            const $priceFacet = $searchForm.find(`[data-key="${priceKey}"]`);

            // Handles custom price input blur
            $priceFacet.on('blur', CUSTOM_FACET_INPUT_SELECTOR, ({ relatedTarget }) => {
                if (this.isAccordion()) {
                    // In the accordion view, there isn't a separate price submit button, so submit their value on blur.
                    // This ensures that preset & custom price values can't be set at the same time in accordion.
                    this.submitCustomRange($priceFacet, priceKey);
                } else {
                    // isRelated will be true when the user changes focus from min-> max or max -> min.
                    const isRelated = $(relatedTarget).is(
                        `${CUSTOM_FACET_INPUT_SELECTOR}, ${CUSTOM_FACET_SUBMIT_SELECTOR}`,
                    );
                    if (!isRelated && $priceFacet.find(':checked').length > 0) {
                        // Disable the price submit button on blur if any price buckets are checked.
                        $priceFacet.find(CUSTOM_FACET_SUBMIT_SELECTOR).prop('disabled', true);
                    }
                }
            });

            // Handles preset price bucket check/uncheck. Note - this event is also handled in the search controller.
            $priceFacet.on('change', FACET_INPUT_SELECTOR, ({ currentTarget }) => {
                if (currentTarget.checked) {
                    const customPriceValue = getCustomRangeValue($priceFacet);
                    if (customPriceValue) {
                        // If a custom range is set, then clear both visible and hidden custom price inputs
                        $priceFacet.find(`${CUSTOM_FACET_INPUT_SELECTOR}, ${CUSTOM_FACET_VALUE_SELECTOR}`).val('');
                        this.removeState(priceKey, customPriceValue, false);
                        this.facetSummaryView.removeFacetTag(priceKey, customPriceValue);
                    }
                    // Disable the price submit button when a preset price bucket is checked.
                    $priceFacet.find(CUSTOM_FACET_SUBMIT_SELECTOR).prop('disabled', true);
                } else if ($priceFacet.find(':checked').length === 0) {
                    // If the last price bucket is unchecked, then enable the price submit button
                    $priceFacet.find(CUSTOM_FACET_SUBMIT_SELECTOR).prop('disabled', false);
                }
            });

            // Enable the price submit button when user clicks a custom price input
            $priceFacet.on('click', CUSTOM_FACET_INPUT_SELECTOR, () => {
                $priceFacet.find(CUSTOM_FACET_SUBMIT_SELECTOR).prop('disabled', false);
            });
        });

        // Binds dimension facet
        const $dimensionFacet = this.getDimensionFacet();

        // Convert inches/centimeters on unit toggle change
        $dimensionFacet.on('change', DIMENSION_UNIT_INPUT_SELECTOR, (e) => {
            const isInches = e.currentTarget.value === DIMENSION_UNITS.INCHES;

            $dimensionFacet
                .find('.js-unit-display')
                .text(isInches ? DIMENSION_UNITS.INCHES : DIMENSION_UNITS.CENTIMETERS);

            $dimensionFacet.find(CUSTOM_FACET_INPUT_SELECTOR).each((_, el) => {
                const $input = $(el);
                const unitValue = parseInt($input.val(), 10);
                if (unitValue > 0) {
                    if (isInches) {
                        $input.val(toInches(unitValue));
                    } else {
                        $input.val(toCentimeters(unitValue));
                    }
                }
            });

            handleSubmitRangeEvent(e);
        });

        // Binds category facet
        $('#js-facet-categories').on('change', FACET_INPUT_SELECTOR, ({ currentTarget }) => {
            const $facetInput = $(currentTarget);
            if (currentTarget.checked) {
                // When a new category is checked, uncheck other categories in their family that are no longer relevant.
                // For example, given the following taxonomy:
                // Furniture -> Tables -> Desks
                // If 'Furniture' is checked when 'Desks' is clicked, then uncheck 'Furniture'.
                // Similarly, if 'Desks' is checked when 'Furniture' is clicked, then uncheck Desks.
                // Warning - this code expects the dom to have a specific structure, so it may break if it's changed.
                const inputValue = $facetInput.val();

                const uncheck = ($input) => {
                    const value = $input.val();
                    if (inputValue !== value) {
                        $input.prop('checked', false);
                        const key = $input.prop('name');
                        this.facetSummaryView.removeFacetTag(key, $input.val());
                        if (this.hasState(key, value)) {
                            this.removeState(key, value, false);
                        }
                    }
                };

                $facetInput.parents(FACET_CHOICE_GROUP_SELECTOR).each((_, el) => {
                    uncheck($(el).find(`> ${FACET_GROUP_LABEL_SELECTOR} ${FACET_INPUT_SELECTOR}`));
                });

                $facetInput
                    .parent()
                    .parent(FACET_CHOICE_GROUP_SELECTOR)
                    .find(FACET_INPUT_SELECTOR)
                    .each((_, el) => {
                        uncheck($(el));
                    });
            }
        });

        // SHIPPING OPTIONS

        const shippingOptions = getShippingOptions();

        // Binds location facet

        const $locationBtn = $('.js-location-btn');

        const locationPopover = new LocationPopover({ $el: $locationBtn });
        locationPopover.bind();

        $locationBtn.on('click', (e) => {
            e.preventDefault();
            locationPopover.setCountryCode($locationBtn.data('country-code'));
            locationPopover.setPostalCode($locationBtn.val());
            locationPopover.show();
        });

        $locationBtn.on(LocationPopoverEvents.SUCCESS, (_, { country_code: countryCode, postal_code: postalCode }) => {
            const countryName = getShippableCountryByCode(countryCode).display;
            $locationBtn.data('country-code', countryCode);
            $locationBtn.html(`${postalCode}, ${countryName}`);
            $locationBtn.val(postalCode);
            locationPopover.unbind();
            locationPopover.hide();

            if (!this.isDirty()) {
                // If more than one location based shipping option is set when location is updated, there is an issue of competing facets.
                // As such, the facet behavior priority is set to the following:
                // 1) If neither ML or FP are checked, ML will be auto-checked and the search will be refreshed.
                // 2) If both ML and FP are checked, ML will take priority, with FP being auto-unchecked and a search refresh performed for ML.
                // 3) If either ML or FP are checked, the search will refresh according to the respective checked facet.

                const $shipsToMyLocationInput = $(
                    `input[name="${FacetTagNames.SHIPPING_OPTIONS}"][value=${shippingOptions.SHIPS_TO_MY_LOCATION}]`,
                );
                const isShipsToMyLocationChecked = $shipsToMyLocationInput.is(':checked');

                const $freeLocalPickupInput = $(
                    `input[name="${FacetTagNames.SHIPPING_OPTIONS}"][value=${shippingOptions.FREE_PICKUP}]`,
                );
                const isFreeLocalPickupChecked = $freeLocalPickupInput.is(':checked');

                const checkShipsToMyLocation = () => {
                    this.pushState(FacetTagNames.LOCATION, postalCode, false);
                    $shipsToMyLocationInput.prop('checked', true);
                    $shipsToMyLocationInput.trigger('change');
                };

                if (!isFreeLocalPickupChecked && !isShipsToMyLocationChecked) {
                    checkShipsToMyLocation();
                } else if (isShipsToMyLocationChecked && isFreeLocalPickupChecked) {
                    this._uncheckShippingOptionsSelection(shippingOptions.FREE_PICKUP);
                    this._uncheckShippingOptionsSelection(shippingOptions.SHIPS_TO_MY_LOCATION);
                    checkShipsToMyLocation();
                } else if (isShipsToMyLocationChecked && !isFreeLocalPickupChecked) {
                    this._uncheckShippingOptionsSelection(shippingOptions.SHIPS_TO_MY_LOCATION);
                    checkShipsToMyLocation();
                } else if (isFreeLocalPickupChecked && !isShipsToMyLocationChecked) {
                    this.removeState(FacetTagNames.LOCATION, undefined, false);
                    this.pushState(FacetTagNames.LOCATION, postalCode, false);
                    this.removeState(FacetTagNames.RADIUS, undefined, false);
                    $(`input[name="${FacetTagNames.RADIUS}"]`).trigger('change');
                }
            }
        });

        $locationBtn.on(LocationPopoverEvents.ERROR, (_, { message: errorMessage }) => {
            const $postalCodeFormGroup = $(document.body)
                .find('.js-location-popover [name="postal_code"]')
                .closest('.js-form-group');
            FormUtils.setFormGroupErrorMessage($postalCodeFormGroup, errorMessage);
        });

        // Binds ships to my location facet

        const $shipsToMyLocationInput = $(
            `input[name="${FacetTagNames.SHIPPING_OPTIONS}"][value=${shippingOptions.SHIPS_TO_MY_LOCATION}]`,
        );
        $shipsToMyLocationInput.on('click', () => {
            const locationVal = this.getLocationValue();
            if (locationVal && $shipsToMyLocationInput.prop('checked')) {
                this.removeState(FacetTagNames.LOCATION, undefined, false);
                this.pushState(FacetTagNames.LOCATION, locationVal, false);
            }
        });

        // Binds radius facet

        const $radiusInput = $(`input[name="${FacetTagNames.RADIUS}"]`);
        const $radiusSelect = $radiusInput.closest('.js-facet-choice').find('select');
        $radiusSelect.on('change', (e) => {
            $radiusInput.val($(e.target).val());

            if (this.getStateVal(FacetTagNames.RADIUS)) {
                // If radius was already in _state, then:
                // 1) Remove the old facet tag
                // 2) Remove the old radius from _state
                // 3) Re-check the $radiusInput (which was unchecked by _removeFacetTag)
                // 4) Trigger a change to update the UI and state with the new value

                this.facetSummaryView.removeFacetTag(FacetTagNames.RADIUS);
                this.removeState(FacetTagNames.RADIUS, undefined, false);
                $radiusInput.prop('checked', true);
                $radiusInput.trigger('change');
            }
        });
    }

    /**
     * Handles submitting custom min/max range inputs.
     * This is implemented with two visible inputs that users can type in, and a third hidden input for tracking value.
     *
     * @param {jQuery} $rangeContainer
     * @param {string} key
     */
    submitCustomRange($rangeContainer, key) {
        const $hiddenInput = $rangeContainer.find(CUSTOM_FACET_VALUE_SELECTOR);
        if ($hiddenInput.length > 0) {
            const currentVal = $hiddenInput.val() || '';
            const newVal = getCustomRangeValue($rangeContainer);

            if (currentVal !== newVal) {
                this.uncheckFacet(key, false);
                $hiddenInput.val(newVal);
                // Send an event from the hidden input to the controller's event handler.
                $hiddenInput.trigger('change');
            }
        }
    }

    /**
     * Handles the hiding and showing of the radius facet, as well as its changes of state
     *
     * @param {boolean} shouldShow Whether or not the radius facet should be displayed
     */
    toggleRadiusVisibility(shouldShow) {
        const $radiusInput = $(`input[name="${FacetTagNames.RADIUS}"]`);

        $radiusInput.prop('checked', shouldShow);
        $radiusInput.closest('.js-facet-choice').toggleClass('hidden', !shouldShow);

        if (!this.isDirty()) {
            this.removeState(FacetTagNames.RADIUS, $radiusInput.val(), false);

            if (shouldShow) {
                this.pushState(FacetTagNames.RADIUS, $radiusInput.val(), false);
            }
        }
    }

    toggleRefinementVisibility($labelEl, $choicesEl, shouldDisplay) {
        $labelEl.attr('aria-expanded', shouldDisplay);
        $choicesEl.collapse(shouldDisplay ? 'show' : 'hide');
    }

    /**
     * @returns {jQuery}
     */
    getSearchForm() {
        return $(SEARCH_FORM_SELECTOR);
    }

    /**
     * @returns {boolean}
     */
    isAccordion() {
        return this._isAccordion;
    }

    /**
     * Determines whether or not the element is the "Free Local Pickup" facet based on its name and value
     *
     * @param {string} elementName The name of the element
     * @param {string} elementValue The value of the element
     * @returns {boolean} True if the element is the "Free Local Pickup" facet; false, if not
     */
    isFreeLocalPickup(elementName, elementValue) {
        return elementName === FacetTagNames.SHIPPING_OPTIONS && elementValue === getShippingOptions().FREE_PICKUP;
    }

    /**
     * @param {Object=} stateObject state object to base current state on. Helpful when `searchView` is marked dirty.
     * @returns {DimensionGroup|null} Returns group if all selected categories have the same type, otherwise null
     */
    getDimensionGroupForCategoriesSelection(stateObject) {
        const stateObj = stateObject || this._state.getState();
        let selectedCategories = stateObj.categories || [];
        if (!$.isArray(selectedCategories)) {
            selectedCategories = [selectedCategories];
        }

        let dimensionGroup = null;

        /**
         * @param {Array} categories
         * @returns {DimensionGroup|null} Returns a dimension group if all categories have homogeneous types, null otherwise
         */
        const getDimensionGroup = (categories) =>
            this.dimensionGroups.find(({ categories: groupCategories }) =>
                categories.every((category) => groupCategories.includes(category)),
            ) || null;

        if (this.dimensionGroups.length === 1 && selectedCategories.length === 0) {
            const categoryFacet = this.facets.find(({ key }) => key === 'categories');
            if (categoryFacet) {
                // If the category facet exists, then check if all available categories are homogenous.

                /**
                 * @param {Array.<Object>} catChoices
                 * @return {Array.<string>} A flat array of strings containing every category code present.
                 */
                const getValues = (catChoices) =>
                    catChoices.reduce(
                        (result, { value, children = [] }) => [value, ...result, ...getValues(children)],
                        [],
                    );
                dimensionGroup = getDimensionGroup(getValues(categoryFacet.count_dicts));
            } else {
                // If category facet isn't present assume dimension group applies to all products in base search result.
                [dimensionGroup] = this.dimensionGroups;
            }
        } else if (selectedCategories.length > 0) {
            // If there are selected categories then find the dimension group that matches them.
            dimensionGroup = getDimensionGroup(selectedCategories);
        }

        return dimensionGroup;
    }

    /**
     * @param {boolean} isAccordion
     */
    setIsAccordion(isAccordion) {
        this._isAccordion = !!isAccordion;
    }

    /**
     * @param {boolean} shouldPersistState True will persist the current state of the form
     * @returns {$.Deferred}
     */
    submit(shouldPersistState) {
        if (mostRecentXhr && mostRecentXhr.state() === 'pending') {
            mostRecentXhr.abort();
        }

        if (shouldPersistState) {
            this.updateStateFromSelections();
            this._state.pushState(this.getStateUrl());
        }

        const xhr = $.ajax({
            dataType: 'json',
            url: this.getStateUrl(),
        });

        xhr.done((data) => {
            const productIdToProductObjMap = data.product_id_to_product_json || {};
            setProductIdToProductObjMap(productIdToProductObjMap);

            const hasProducts = hasSearchResults();
            const pagerFullHtml = (data.pager_full_html || '').trim();
            const pagerTruncatedHtml = (data.pager_truncated_html || '').trim();

            const $productGrid = $('.js-product-grid');
            $('.js-num-items').text(data.num_items_copy);
            $('.js-current-page').text(data.current_page);
            $('.js-total-pages').text(data.total_pages);
            $('.js-pagination-numbered').replaceWith(pagerFullHtml);
            $('.js-pager-container-truncated').html(pagerTruncatedHtml);
            $productGrid.html(hasProducts ? data.products_html : '');
            $productGrid.trigger('product.grid.change');
            $('#js-products-ldjson').html(data.products_ldjson || '');
            $('.js-layout-not-found').html(hasProducts ? '' : data.not_found_html);
            $('#js-saved-search-form-container').html(data.saved_search_form_html);
            $(
                '.search-results-header .js-async-btn-saved-search, .saved-search-verbose .js-async-btn-saved-search',
            ).html(data.btn_saved_search_html);
            const $pagerContainer = $('.js-pager-container-full');
            if (!$pagerContainer.find('.js-pagination-numbered').length) {
                $pagerContainer.prepend(pagerFullHtml);
            }
            $('.js-saved-search-description').text(data.saved_search_description);
            $('.js-product-grid-container').toggle(hasProducts);
            $('#js-facet-sort').toggleClass('hidden', !hasProducts);

            this.updateErrors(data.search_errors);
            this.markClean();

            if (!this.facets.length || !this._facetGroupings.length) {
                this.setFacets(data.facet_dicts || []);
                this._facetGroupings = data.facet_groupings || [];
                this.buildFacets();
                this.bindFacets();
                this.updateFacetVisibility();
                this.bindRefinementVisibility();
                this.displayFacetTags();
                this.updateFormSelections();
            }
        }).always(() => {
            mostRecentXhr = null;
        });

        mostRecentXhr = xhr;
        return xhr;
    }

    /**
     * Validates that the current state is valid with the server. Used on small screens to check certain facet changes.
     *
     * @returns {$.Deferred}
     */
    validateState() {
        return $.ajax({
            url: getSearchValidationEndpointUrl(),
            data: this.getDirtyState(),
        }).always((data) => {
            this.updateErrors(data.search_errors || {});
        });
    }

    /**
     * Updates the UI and state with errors from the server
     *
     * FIXME CHAIR-7048: This function is not "pure", as in it does not unconditionally and successfully revert to the
     * last valid UI in the presence of errors. This is because we cannot know which multi-select options were
     * unchecked by a call to 'changeSelection'.
     * As very few fields require validation for the moment, this is not a big deal, but it's worth thinking about.
     *
     * @param {Object.<String, Object.<String, String>>} errors A mapping of field names to:
     *                  1) a list of errors to be displayed in that field, and
     *                  2) a list of values to be removed from state, where an empty list indicates all values
     */
    updateErrors(errors) {
        const $searchForm = this.getSearchForm();

        // Zero out existing errors
        const $errorLists = $searchForm.find('.js-errors');
        $errorLists.html('');
        $errorLists.closest('.js-facet-choice').removeClass('has-error');

        if (errors && !$.isEmptyObject(errors) && $.isPlainObject(errors)) {
            $.each(errors, (fieldName, errorsDict) => {
                // NOTE: if a field has invalid_values === [], then *all* its values are invalid. But if it has
                // invalid_values === undefined, then it truly has no invalidValues (however, it still may have
                // errors to display)
                const invalidValues = errorsDict.invalid_values;
                const displayErrors = errorsDict.errors;

                // Remove all invalid facet tags and state values
                if (invalidValues) {
                    for (let i = 0; i < invalidValues.length; i++) {
                        const invalidValue = invalidValues[i];
                        this.facetSummaryView.removeFacetTag(fieldName, invalidValue);
                        this.removeState(fieldName, invalidValue, false);

                        if (this.isFreeLocalPickup(fieldName, invalidValue)) {
                            $(`input[name="${FacetTagNames.RADIUS}"]`).closest('.js-facet-choice').addClass('hidden');
                        }
                    }

                    // Update selections to reflect the removal of certain values
                    const $facet = $searchForm.find(`input[name="${fieldName}"]`).closest(FACET_SELECTOR);
                    this.updateSelectionIndicator($facet);
                }

                // Display errors which go in this field
                if (displayErrors) {
                    const $displayFacetChoice =
                        fieldName === FacetTagNames.LOCATION
                            ? $searchForm.find('.js-location-btn').closest('.js-facet-choice')
                            : $searchForm.find(`input[name="${fieldName}"]`).closest('.js-facet-choice');

                    $displayFacetChoice.addClass('has-error');

                    const $errorList = $('<ul></ul>', {
                        class: 'errorlist',
                    });

                    for (let j = 0; j < displayErrors.length; j++) {
                        $errorList.append(
                            $('<li></li>', {
                                text: displayErrors[j].display,
                            }),
                        );
                    }

                    $displayFacetChoice.find('.js-errors').html($errorList);
                }
            });

            // Replace the invalid state with a valid state
            HistoryUtils.replaceState(this._state.getState(), document.title, this.getStateUrl());
        }
    }

    /**
     * @returns {boolean} True indicates the form has changes that have not been applied
     */
    isDirty() {
        return this._isDirty;
    }

    /**
     * Indicates the form's changes have been applied or no changes were made
     */
    markClean() {
        this._isDirty = false;
    }

    /**
     * Indicates the form has changes that have not been applied
     */
    markDirty() {
        this._isDirty = true;
    }

    /**
     * Changes the selection and aspects of `this._state` depending on the selection change
     * and the status (dirty or not) of this class.
     *
     * @param {HTMLElement} domElement The element whose selection has changed
     * @param {boolean=} wasFacetTagRemoved True indicates the selection change is due to a facet tag removal
     */
    changeSelection(domElement, wasFacetTagRemoved = false) {
        const $el = $(domElement);
        const elementName = $el.attr('name');
        const elementValue = $el.val();
        // Custom value facets are hidden inputs that contain a value that's custom set by the user via input boxes.
        const isCustomFacet = $el.is(CUSTOM_FACET_VALUE_SELECTOR);
        const isSort = elementName === 'sort';
        const isChecked = $el.is(':checked');

        // with any state change we need to remove page since it's a new set of results that starts on page 1
        // but only do this if the searchView isn't dirty...
        if (!this.isDirty()) {
            this.removeState('page', undefined, false);
        }

        // when pushing states be sure not to persist the change because some facets
        // might be unchecked as a result of this selection.
        //
        // after all selections and deselections are made, call `_pushState()` at the end...
        if (isChecked || isSort) {
            if (isChecked) {
                this._makeCheckSelection($el, elementName, elementValue);
            }
            if (!this.isDirty()) {
                this.pushState(elementName, elementValue, false);
            }
        } else if (isCustomFacet) {
            this.makeCustomValueSelection(domElement, wasFacetTagRemoved);
        } else if (elementName === FacetTagNames.SHIPPING_OPTIONS) {
            this._uncheckShippingOptionsSelection(elementValue, wasFacetTagRemoved);
        } else {
            this._uncheckSelection(elementName, elementValue, wasFacetTagRemoved);
        }

        let stateObject = this._state.getState();
        if (this.isDirty()) {
            stateObject = this.getDirtyState();
        }

        this.updateFacetVisibility(elementName, stateObject);

        if (!this.isDirty()) {
            this._state.pushState(this.getStateUrl());
        }

        this.updateSelectionIndicator($el.closest(FACET_SELECTOR));
    }

    /**
     * Handles updating state when a checkbox is checked
     *
     * @param {jQuery} $el The checked node
     * @param {string} elementName Name of the checked node
     * @param {string} elementValue Value of the checked node
     */
    _makeCheckSelection($el, elementName, elementValue) {
        const isMultiSelect = $el.data('is-multi-select');

        this.facetSummaryView.addFacetTag(elementName, elementValue);

        const $choicesToDeselect = $el
            .closest(FACET_CHOICES_SELECTOR)
            .find(`[data-is-multi-select="${!isMultiSelect}"]`)
            .not($el);

        $.each($choicesToDeselect.not($el), (_, el) => {
            if (el.checked) {
                el.checked = false;
                const { name } = el;
                const val = el.value;
                this.facetSummaryView.removeFacetTag(name, val);

                if (!this.isDirty()) {
                    this.removeState(name, val, false);
                }
            }
        });
        this.updateCompetingFacets($el[0]);

        if (this.facetBuilder.isLocationRequired(elementValue)) {
            const locationVal = this.getLocationValue();
            if (!this.isDirty() && locationVal !== this.getStateVal(FacetTagNames.LOCATION)) {
                this.removeState(FacetTagNames.LOCATION, undefined, false);
                this.pushState(FacetTagNames.LOCATION, locationVal, false);
            }

            if (locationVal && this.isFreeLocalPickup(elementName, elementValue)) {
                this.toggleRadiusVisibility(true);
            }
        }
    }

    /**
     * Updates state when a checkbox is unselected
     *
     * @param {string} elementName Name of the element being unchecked
     * @param {string} elementValue Value of the element being unchecked
     * @param {boolean} wasFacetTagRemoved Whether or not this state update is the result of a facet tag being removed.
     */
    _uncheckSelection(elementName, elementValue, wasFacetTagRemoved) {
        if (!wasFacetTagRemoved) {
            this.facetSummaryView.removeFacetTag(elementName, elementValue);
        }

        if (!this.isDirty()) {
            this.removeState(elementName, elementValue, false);
        }
    }

    /**
     * Updates state when a shipping_options checkbox is unselected
     *
     * @param {string} elementValue Value of the element being unchecked
     * @param {boolean} wasFacetTagRemoved Whether or not this state update is the result of a facet tag being removed.
     */
    _uncheckShippingOptionsSelection(elementValue, wasFacetTagRemoved) {
        const shippingOptions = getShippingOptions();
        this._uncheckSelection(FacetTagNames.SHIPPING_OPTIONS, elementValue, wasFacetTagRemoved);

        if (
            elementValue === shippingOptions.SHIPS_TO_MY_LOCATION &&
            !this.isDirty() &&
            !this.getStateVal(FacetTagNames.RADIUS)
        ) {
            this.removeState(FacetTagNames.LOCATION, undefined, false);
        }

        if (elementValue === shippingOptions.FREE_PICKUP) {
            this.toggleRadiusVisibility(false);

            const shippingOptionsStateVal = this.getStateVal(FacetTagNames.SHIPPING_OPTIONS) || [];
            if (!this.isDirty() && !shippingOptionsStateVal.includes(shippingOptions.SHIPS_TO_MY_LOCATION)) {
                this.removeState(FacetTagNames.LOCATION, undefined, false);
            }
        }
    }

    /**
     * Updates state when a custom facet value changes
     *
     * @param {HTMLElement} domElement The input element with the custom value
     * @param {boolean} wasFacetTagRemoved Whether this update is the result of a facet tag being removed
     */
    makeCustomValueSelection({ name, value }, wasFacetTagRemoved) {
        // There should only ever be a single custom value selection at a time. It should also override preset values.
        this.facetSummaryView.removeFacetTag(name);
        if (!this.isDirty()) {
            this.removeState(name);
        }
        if (!wasFacetTagRemoved) {
            if (value) {
                this.facetSummaryView.addFacetTag(name, value, true);
            }

            if (!this.isDirty()) {
                this.pushState(name, value, false);
            }
        }
    }

    /**
     * @param {number} pageSize The number of products to display per page
     */
    setPageSize(pageSize) {
        this.removeState('page_size', undefined, false);
        if (pageSize !== this.defaultPageSize) {
            this.pushState('page_size', pageSize, false);
        }

        this._state.pushState(this.getStateUrl());
    }

    /**
     * Enforces the mutually exclusive nature of certain facets. For example, dimension filters cannot
     * also be used when a dynamic filter like rug size is used.
     *
     *
     * @param {HTMLElement} el The element that was most recently used
     */
    updateCompetingFacets(el) {
        const lastChangedElementName = el.name;

        const dimensions = getDimensionFieldNames();
        const isDimension = $.inArray(lastChangedElementName, dimensions) !== -1;
        const isCompetingFacet = $.inArray(lastChangedElementName, this.competingFacetsDimensions) !== -1;

        if (isDimension) {
            const selectorParts = [];
            $.each(this.competingFacetsDimensions, (i, val) => {
                selectorParts.push(`[name="${val}"]:checked`);
            });

            this.$searchForm.find(selectorParts.join(',')).each((_, el) => {
                el.checked = false;
                const { name } = el;
                const val = el.value;
                this.facetSummaryView.removeFacetTag(name, val);
                this.updateSelectionIndicator($(el).closest('.js-facet'));

                if (!this.isDirty()) {
                    this.removeState(name, val, false);
                }
            });
        } else if (isCompetingFacet) {
            $.each(dimensions, (_, name) => {
                this.facetSummaryView.removeFacetTag(name);

                if (!this.isDirty()) {
                    this.removeState(name);
                }
            });
        }
    }

    /**
     * Clears the state object but maintains the query term, if any
     */
    clearState() {
        const newState = {};
        const { q } = this._state.getState();
        if (q && !(isShop() || isCollection())) {
            newState.q = q;
        }

        this._state.setStateObject(newState);
    }

    /**
     * Clears custom facet choice inputs.
     */
    clearCustomInputs() {
        this.getSearchForm().find(`${CUSTOM_FACET_INPUT_SELECTOR}, ${CUSTOM_FACET_VALUE_SELECTOR}`).val('');
    }

    /**
     * Appends a new state to the state object, and pushes the state to the browser history.
     * If you don't want to change the browser history, pass in `false` as the 3rd argument.
     *
     * @param {string} fieldName The name of the facet input being added to the state object
     * @param {string} fieldValue The value of the facet input being added to the state object
     * @param {boolean=} shouldPersist True indicates the state should be persisted to window.history
     */
    pushState(fieldName, fieldValue, shouldPersist = true) {
        let currentVal = this._state.getStateVal(fieldName);
        const isSort = fieldName === 'sort';

        if (currentVal && !isSort) {
            if ($.isArray(currentVal)) {
                currentVal.push(fieldValue);
            } else {
                currentVal = [currentVal, fieldValue];
            }
        } else {
            currentVal = fieldValue;
        }

        this._state.setStateVal(fieldName, currentVal);

        if (shouldPersist) {
            this._state.pushState(this.getStateUrl());
        }
    }

    /**
     * Removes an existing state to the state object
     *
     * @param {string} fieldName The name of the facet input being removed from the state object
     * @param {string=} fieldValue The value of the facet input being removed from the state object
     * @param {boolean=} shouldPersist True indicates the state should be persisted to window.history.
     */
    removeState(fieldName, fieldValue, shouldPersist = true) {
        this._state.removeState(fieldName, fieldValue, shouldPersist);
    }

    /**
     * @param {Object} obj
     * @deprecated
     * @todo: refactor clients to not use this...
     */
    setStateObject(obj) {
        this._state.setStateObject(obj);
    }

    /**
     * Hides search facets and modal backdrop. Resets state and UI search form if "dirty"
     */
    hideFacets() {
        // If selections have been made but not applied reset the form
        // selections to their previous state and update the facet tags.
        if (this.isDirty()) {
            this.clearState();
            this._state.updateStateFromUri();
            this.updateFormSelections();
            this.updateFacetVisibility();
            this.displayFacetTags();
            this.markClean();
        }
        this.hideFacetForm();
        this.hideFacetBackdrop();
    }

    /**
     * Converts the current state into a datastructure that can then be marshaled into and displayed as facet tags
     */
    displayFacetTags() {
        const stateArray = [];

        $.each(this._state.getState(), (k, v) => {
            if ($.inArray(k, FACET_KEYS_TO_SKIP) === -1 && $.inArray(k, this.filterAndSortNames) !== -1) {
                if ($.isArray(v)) {
                    for (let i = 0; i < v.length; i++) {
                        stateArray.push({
                            name: k,
                            value: v[i],
                        });
                    }
                } else {
                    stateArray.push({
                        name: k,
                        value: v,
                    });
                }
            }
        });

        this.facetSummaryView.displayFacetTags(stateArray);
    }

    /**
     * Hides the facet choices
     */
    hideFacetForm() {
        $(SEARCH_FORM_SELECTOR).removeClass('visible');
    }

    /**
     * Hides the facet choices modal backdrop
     */
    hideFacetBackdrop() {
        // remove modal-open class from body
        ViewportUtil.unlockViewport();

        // hide and remove the facet backdrop
        $('.facet-backdrop').removeClass('in').remove();
    }

    /**
     * Displays the facet choices and modal backdrop
     */
    showFacets() {
        const $body = $(document.body);
        const $backdrop = $('<div class="modal-backdrop facet-backdrop fade in"></div>');
        const $searchForm = $(SEARCH_FORM_SELECTOR);

        ViewportUtil.lockViewport();

        $backdrop.appendTo($body).on('click', this.hideFacets.bind(this));
        $searchForm.addClass('visible');
    }

    /**
     * Syncs the forms UI with the current search state
     */
    updateFormSelections() {
        const $searchForm = this.getSearchForm();
        $searchForm.find('.js-facet-input:checked').prop('checked', false);

        $.each(this._state.getState(), (key, values) => {
            if (this.filterAndSortNames.includes(key) && !getDimensionFieldNames().includes(key)) {
                if ($.isArray(values)) {
                    values.forEach((value) => {
                        this.updateFacetCheckboxSelection($searchForm, key, value, true);
                    });
                } else {
                    this.updateFacetCheckboxSelection($searchForm, key, values, true);
                }
            }
        });

        getCustomRangeFieldNames().forEach((fieldName) => {
            const $customRangeWrapper = $searchForm.find(`[data-key="${fieldName}"]`);
            if ($customRangeWrapper.length > 0) {
                const value = this.getStateVal(fieldName);
                const $customFacetValue = $customRangeWrapper.find(CUSTOM_FACET_VALUE_SELECTOR);
                const $customFacetMin = $customRangeWrapper.find(MIN_RANGE_SELECTOR);
                const $customFacetMax = $customRangeWrapper.find(MAX_RANGE_SELECTOR);
                if (!value) {
                    // If there isn't a value then clear the custom inputs.
                    $customFacetValue.add($customFacetMin).add($customFacetMax).val('');
                } else if (value !== $customFacetValue.val() && !$.isArray(value)) {
                    // If the state val mismatches the inputs value, sync their states.
                    $customFacetValue.val(value);

                    // Then manually set the min/max inputs
                    const [minPart, maxPart] = value.split('-');
                    $customFacetMin.val(minPart || '');
                    $customFacetMax.val(maxPart || '');
                }
            }
        });

        // SHIPPING & DELIVERY
        const shippingOptions = getShippingOptions();

        // Free Local Pickup
        const shippingOptionsStateVal = this.getStateVal(FacetTagNames.SHIPPING_OPTIONS) || [];
        const isFreeLocalPickupChecked =
            $searchForm.find(`input[name="${FacetTagNames.SHIPPING_OPTIONS}"][value=${shippingOptions.FREE_PICKUP}]`)
                .length &&
            (shippingOptionsStateVal === shippingOptions.FREE_PICKUP ||
                shippingOptionsStateVal.includes(shippingOptions.FREE_PICKUP));

        $searchForm
            .find(`input[name="${FacetTagNames.RADIUS}"]`)
            .closest('.js-facet-choice')
            .toggleClass('hidden', !isFreeLocalPickupChecked);

        $(FACET_SELECTOR).each((_, el) => {
            this.updateSelectionIndicator($(el));
        });
    }

    /**
     * Groups facets using the facetGroupings data structure
     *
     * More specifically, the count_dicts contained in each grouped facet are injected into that facet's group, so that
     * the grouping of [{key: A, count_dicts: [1, 2]}, {key: B, count_dicts: [3, 4]}] becomes
     * [{key: C, count_dicts: [1, 2, 3, 4]}]
     *
     * @returns {Array.<Facet>} An array of grouped facets
     */
    groupFacets() {
        const groupedFacets = [];

        for (let i = 0; i < this.facets.length; i += 1) {
            const facet = this.facets[i];
            let shouldAddFacet = true;

            if (facet.count_dicts) {
                for (let j = 0; j < this._facetGroupings.length; j += 1) {
                    const facetGrouping = this._facetGroupings[j];

                    if ($.inArray(facet.key, facetGrouping.facets) !== -1) {
                        const groupKey = facetGrouping.key;
                        let isNewKey = true;

                        for (let k = 0; k < groupedFacets.length; k += 1) {
                            const groupedFacet = groupedFacets[k];

                            if (groupedFacet.key === groupKey) {
                                groupedFacet.count_dicts = groupedFacet.count_dicts.concat(facet.count_dicts);
                                isNewKey = false;
                                break;
                            }
                        }

                        if (isNewKey) {
                            groupedFacets.push({
                                key: groupKey,
                                label: facetGrouping.label,
                                count_dicts: facet.count_dicts,
                            });
                        }

                        shouldAddFacet = false;
                        break;
                    }
                }
            }

            if (shouldAddFacet) {
                groupedFacets.push(facet);
            }
        }

        return groupedFacets;
    }

    /**
     * Builds facets
     */
    buildFacets() {
        const isExpanded = isMinLargeDesktop();
        const facets = this.groupFacets().map((facet, i) => {
            const $facet = this.facetBuilder.marshalFacet(facet, isExpanded);
            const shouldCollapseFacet =
                [
                    'art_subjects',
                    'city',
                    'pattern',
                    'primary_stone',
                    'rug_construction',
                    'styles',
                    'seating_set_size',
                ].includes(facet.key) && this.getStateVal(facet.key) === undefined;

            if (shouldCollapseFacet) {
                this._collapseFacet($facet);
            }

            if (facet.key === this.dimensionGroupDisplayAfterKey) {
                this.dimensionGroupDisplayIndex = i + 1; // add one to insert after
            }

            $facet.addClass('hidden');
            return $facet;
        });

        if (this.dimensionGroupDisplayIndex === -1) {
            this.dimensionGroupDisplayIndex = 0;
        }

        // insert the dimension group into the facets where appropriate
        facets.splice(
            this.dimensionGroupDisplayIndex,
            0,
            this.facetBuilder.marshalDimensionFilterContainer(isExpanded),
        );

        $('#js-search-form .js-facet').remove();

        if (isCollection() || isShop()) {
            const urlSearchParams = new URLSearchParams(window.location.search);
            facets.push(
                $('<input/>', {
                    type: 'hidden',
                    name: FacetTagNames.QUERY,
                    value: urlSearchParams.get(FacetTagNames.QUERY),
                }),
            );
        }

        this.getSearchForm().find('.facet').after(facets);
    }

    /**
     * Collapses facets (desktop only) with a "show more" link
     * @param {jQuery} $facet
     */
    _collapseFacet($facet) {
        const $facetChoices = $facet.find('.js-facet-choice');
        const showMoreThreshold = 7;

        // ensure > 7 because "clear all" is a facet choice
        if ($facetChoices.length > showMoreThreshold) {
            $facetChoices.each((i, el) => {
                if (i >= showMoreThreshold - 1) {
                    $facetChoices.eq(i).addClass('hidden-lg hidden-md');
                }
            });

            const $loadMore = $('<a></a>', {
                href: '#',
                class: 'visible-md visible-lg js-load-more',
                text: `Show ${$facetChoices.length - showMoreThreshold} more`,
            });

            const $loadMoreContainer = $('<li></li>', {
                class: 'facet-choice js-facet-choice',
                role: 'tab',
            }).append($loadMore);

            $facet.find(FACET_CHOICES_SELECTOR).append($loadMoreContainer);
        }
    }

    /**
     * Removes "collapsed" state for facet (desktop only)
     * @param {jQuery} $facet
     */
    uncollapseFacet($facet) {
        const $choices = $facet.find('.js-facet-choice');

        $facet.find('.js-load-more').closest('.js-facet-choice').remove();

        $choices.each((i, el) => {
            const $el = $choices.eq(i);
            $el.removeClass('hidden-lg hidden-md');
        });
    }

    /**
     * @returns {string} The value of the location facet
     */
    getLocationValue() {
        return $('.js-location-btn').val();
    }

    /**
     * @return {jQuery}
     */
    getDimensionFacet() {
        return this.getSearchForm().find('.js-dimension-facet');
    }

    /**
     * Updates the dimension facet based on selected categories.
     *
     * @param {Object=} stateObject Optional state object to use in lieu of this._state
     */
    updateDimensionFacet(stateObject) {
        const dimensionGroup = this.getDimensionGroupForCategoriesSelection(stateObject);
        const $dimensionFacet = this.getDimensionFacet();
        const $groupContainer = $dimensionFacet.find('.js-dimension-group-container');
        const shouldHide = dimensionGroup === null || dimensionGroup.filters.length === 0;
        $dimensionFacet.toggleClass('hidden', shouldHide);

        if (shouldHide) {
            $dimensionFacet.find(FACET_CHOICES_SELECTOR).html(''); // wipe the HTML to play nicely with `showAllFacets`
            $.each(getDimensionFieldNames(), (_, name) => {
                if (stateObject !== undefined) {
                    delete stateObject[name];
                }

                this.facetSummaryView.removeFacetTag(name, undefined);
            });
        } else {
            $groupContainer.find('.js-facet-choice').remove(); // Remove because they will be rebuilt below.
            dimensionGroup.filters.forEach((filter) => {
                $groupContainer.append(this.facetBuilder.marshalDimensionFilter(filter));
            });
        }
    }

    /**
     * Logic for conditionally showing/hiding facets.
     *
     * @param {string=} opt_elementName Optional element name to base facet visibility changes on
     * @param {Object=} opt_stateObject Optional state object to base facet visibility on. Useful on tablet/phone uis
     * @param {boolean=} opt_shouldUpdateDimensionFilters Optional whether to force an update (or forcibly suppress
     *                                                              an update) to the dimension filters.
     *
     * @see https://docs.google.com/drawings/d/1yF09jHd9tuKGUDvOO-UdJXF7zzcjwAfu_TVURXWOQ98/edit
     */
    updateFacetVisibility(opt_elementName, opt_stateObject, opt_shouldUpdateDimensionFilters) {
        // initialize variables that get reused so editors don't whine about
        // duplicate variables being declared as a result of hoisting
        let key;
        let compatibleFacets = [];
        const elementName = opt_elementName || 'categories';
        const stateObject = opt_stateObject || this._state.getState();
        const isCategoryTree = elementName === 'categories';

        let shouldUpdateDimensionFilters = opt_shouldUpdateDimensionFilters;
        if (shouldUpdateDimensionFilters === undefined) {
            shouldUpdateDimensionFilters = isCategoryTree;
        }

        if ($.isEmptyObject(stateObject)) {
            // If there is nothing selected display all facets including dynamic facets
            // to encourage people to click *something* to narrow their results
            this.showAllFacets();
        } else if (!stateObject[elementName] || stateObject[elementName].length === 0) {
            // If a facet choice has been deselected and there are no other choices, attempt to
            // display dynamic facets based on explicitly checked L1/L2s (if any).
            //
            // `categories` is used to kick off updating facet visibility during initial
            // facet creation so if there is no state for this key, just show everything
            if (isCategoryTree) {
                this.showAllFacets();
            } else {
                // do not pass in elementName so we go through the compatible facets flow next time but
                // do pass through the state object so we maintain a modified "dirty" state if applicable...
                this.updateFacetVisibility(
                    undefined,
                    stateObject,
                    $.inArray(elementName, getDimensionFieldNames()) === -1,
                );
            }
        } else {
            if (isCategoryTree) {
                // If someone has (de)selected an L1 or L2:
                //
                // 1. Get all facets that can be applied to any of the selected L1/L2s (union of compatible facets)
                // 2. Show all compatible facets
                // 3. Hide all other facets

                let categoryTreeState = stateObject.categories;

                // state object values are strings until an additional value is added to the same key
                if (!$.isArray(categoryTreeState)) {
                    categoryTreeState = [categoryTreeState];
                }

                // get all facets compatible with the explicitly checked L1/L2s...
                for (let i = 0; i < categoryTreeState.length; i++) {
                    compatibleFacets = compatibleFacets.concat(
                        this._getCompatibleFacets(elementName, categoryTreeState[i]),
                    );
                }
                compatibleFacets = [...new Set(compatibleFacets)];
            } else {
                // Someone clicked a facet that's not an L1/L2 so we should display
                // all the other facets that are compatible that one
                compatibleFacets = this._getCompatibleFacetsForAFacet(elementName);
            }

            // show compatible facets and hide incompatible facets
            if (compatibleFacets.length) {
                for (let j = 0; j < this.facets.length; j++) {
                    key = this.facets[j].key;
                    if ($.inArray(key, compatibleFacets) === -1) {
                        this._hideAndUncheckFacet(key);
                    } else if (isCategoryTree) {
                        this.showFacet(key);
                    }
                }
            }
        }

        if (shouldUpdateDimensionFilters) {
            this.updateDimensionFacet(opt_stateObject);
        }
    }

    /**
     * Hides and unchecks a facet
     * @param {string} facetKey The key of the facet to hide and uncheck
     * @param {boolean=} shouldPersist false indicates the change should not be persisted.
     */
    _hideAndUncheckFacet(facetKey, shouldPersist = true) {
        const $facet = this.uncheckFacet(facetKey, shouldPersist);
        $facet.addClass('hidden');
    }

    /**
     * Unchecks a facet and potentially persists the change
     * @param {string} facetKey The key that describes the facet in `this._state`
     * @param {boolean=} shouldPersist false indicates the change should not be persisted.
     * @returns {jQuery} The facet jQuery element
     */
    uncheckFacet(facetKey, shouldPersist = true) {
        const isShippingAndDelivery = facetKey === 'shipping_and_delivery';
        const $facet = $(`.js-facet[data-key="${facetKey}"]`);
        const facetKeys = []; // If the facet is a heterogeneous grouping, it may contain more than one key

        $facet.find('input').each((_, el) => {
            facetKeys.push(el.name);

            if (el.type === 'checkbox') {
                el.checked = false;
            }

            if (isShippingAndDelivery && this.isFreeLocalPickup(el.name, el.value)) {
                $(`input[name="${FacetTagNames.RADIUS}"]`).closest('.js-facet-choice').addClass('hidden');
            }

            this.facetSummaryView.removeFacetTag(el.name, el.value);
        });

        if (isShippingAndDelivery && !this.isDirty()) {
            this.removeState(FacetTagNames.LOCATION, undefined, false);
        }

        new Set(facetKeys).forEach((key) => {
            this.removeState(key, undefined, shouldPersist);
        });

        this.updateSelectionIndicator($facet);

        return $facet;
    }

    /**
     * Shows a facet
     * @param {string} facetKey The key of the facet to display
     */
    showFacet(facetKey) {
        $(`input[name="${facetKey}"]`).closest(FACET_SELECTOR).removeClass('hidden');
    }

    /**
     * Makes all facets visible
     */
    showAllFacets() {
        const $facets = $(FACET_SELECTOR);
        $facets.each((_, el) => {
            const $facet = $(el);
            // only show the facet if it has something that can be selected
            if ($facet.find(FACET_CHOICES_SELECTOR).children().length > 0) {
                $facet.removeClass('hidden');
            }
        });
    }

    /**
     * @returns {string} The URL of the current state of the search form
     */
    getStateUrl() {
        let url = this._state.getStateUrl();
        const paramMaps = ChairishUri.getParamMaps(url);

        let hasAcceptableArrayParams = false;
        $.each(this._state.getState(), (key, values) => {
            // filter out states that don't have values for whatever reason
            // in addition to states that are only present in GET params...
            if (this._state.isAcceptableArrayParam(key, values)) {
                hasAcceptableArrayParams = true;
            }
        });

        if (paramMaps.length) {
            // we don't want to prepend /search/ if the only queryStringParam is `page`...
            const isPageNumberTheOnlyParam = paramMaps.length === 1 && paramMaps[0].name.indexOf('page') !== -1;

            // Note: We prepend /search when there is a filter applied (paramMaps.length >= 1)
            //       for SEO purposes; if a bot crawls this URL our robots.txt file forbids and noindexes
            //       it (in addition to how the server handles URLs starting with /search...) so we don't
            //       have lots of similar pages indexed...
            if (!url.startsWith('/search') && hasAcceptableArrayParams && !isPageNumberTheOnlyParam) {
                url = `/search${url}`;
                MetaUtils.setRobotsMetaTag('noindex, nofollow');
            }
        } else {
            if (url !== '/search') {
                url = url.replace('/search', '');
            }
            MetaUtils.restoreRobotsMetaTag();
        }

        return url;
    }

    /**
     * Restores the scroll position based on the presence and value of the `_scrollTop` state.
     */
    restoreScrollPosition() {
        if (this.getStateVal('_scrollTop')) {
            $(window).scrollTop(this.getStateVal('_scrollTop'));
        }
    }

    /**
     * Ensures window.history.state isn't null. This is only useful for pressing the "back"
     * button after filtering the first time.
     */
    _ensureState() {
        // Note: Below on `popstate` we check `if (e.originalEvent.state) {}` so we need
        //       to replace the initial state with the current state object. We cannot
        //       remove the check below because Safari fires `popstate` on the initial page
        //       load and when that happens `e.originalEvent.state === null`.
        //
        //       Replacing the initial state here does not affect Safari's initial call to
        //       `popstate` and it allows us to get back to our iintial state if someone
        //       selects a facet and then presses the back button.
        //
        //       The replacement of state must happen here and only when window.history.state === null
        //       because otherwise the Back-Forward Cache will disregard pagination as it sees fit.
        if (HistoryUtils.hasReplaceState() && HistoryUtils.getState() === null) {
            HistoryUtils.replaceState(this._state.getState(), document.title, this.getStateUrl());
        }
    }

    /**
     * Given a facet choice's key and value this will return a list of compatible facets
     *
     * @param {string} key
     * @param {string} value
     * @returns {Array.<string>}
     */
    _getCompatibleFacets(key, value) {
        return this._facetKeyValuetoCompatibleFacetsMap[`${key}|${value}`] || [];
    }

    /**
     * Given a facet's key this will return a list of compatible facets
     *
     * @param facetName
     * @returns {Array.<string>|Array}
     */
    _getCompatibleFacetsForAFacet(facetName) {
        return this._facetKeytoCompatibleFacetKeyMap[facetName] || [];
    }

    /**
     * Sets internal facet datastructures
     *
     * @param {Array.<FacetChoice>} facets
     */
    setFacets(facets) {
        this.facets = facets;

        this.updateFilterAndSortNames();
        this._mapFacetChoiceToCompatibleFacetKey();
        this._mapFacetNameToCompatibleFacetKey();
    }

    /**
     * Maps each facet choice to its compatible facets
     */
    _mapFacetChoiceToCompatibleFacetKey() {
        this._facetKeyValuetoCompatibleFacetsMap = {};

        const mapCompatibleFacets = (choice) => {
            if (choice.children) {
                for (let q = 0; q < choice.children.length; q++) {
                    mapCompatibleFacets(choice.children[q]);
                }
            }

            if (choice.compatible_facets) {
                this._facetKeyValuetoCompatibleFacetsMap[`${choice.key}|${choice.value}`] = choice.compatible_facets;
            }
        };

        for (let i = 0; i < this.facets.length; i++) {
            const facet = this.facets[i];

            if (facet.count_dicts) {
                // count_dicts isn't present when is_range is
                for (let j = 0; j < facet.count_dicts.length; j++) {
                    mapCompatibleFacets(facet.count_dicts[j]);
                }
            }
        }
    }

    /**
     * Maps each facet to its compatible facet choices
     */
    _mapFacetNameToCompatibleFacetKey() {
        let categoryTreeFacet;
        const { facets } = this;
        const facetNames = [];
        const facetNamesToFacets = {};

        for (let i = 0; i < facets.length; i++) {
            const { key } = facets[i];
            facetNames.push(key);
            facetNamesToFacets[key] = [];

            if (key === 'categories') {
                categoryTreeFacet = facets[i];
            }
        }

        const storeCompatibleFacets = function (childFacet) {
            if (childFacet.compatible_facets && childFacet.is_multi_select) {
                const compatibleFacets = childFacet.compatible_facets;

                for (let l = 0; l < compatibleFacets.length; l++) {
                    const facetName = compatibleFacets[l];
                    const facetValue = facetNamesToFacets[facetName];

                    if (facetValue !== undefined) {
                        facetNamesToFacets[facetName] = facetValue.concat(compatibleFacets);
                    }
                }
            }
        };

        if (categoryTreeFacet) {
            for (let j = 0; j < categoryTreeFacet.count_dicts.length; j++) {
                const facet = categoryTreeFacet.count_dicts[j];

                if (facet.compatible_facets) {
                    storeCompatibleFacets(facet);
                } else if (facet.children) {
                    for (let k = 0; k < facet.children.length; k++) {
                        storeCompatibleFacets(facet.children[k]);
                    }
                }
            }
        }

        $.each(facetNamesToFacets, (k, v) => {
            facetNamesToFacets[k] = v.filter((value, index, self) => self.indexOf(value) === index);
        });

        this._facetKeytoCompatibleFacetKeyMap = facetNamesToFacets;
    }

    /**
     * Creates a list of arguments that are allowed to appear in a URL
     */
    updateFilterAndSortNames() {
        // `page` is added here by default because when we build URLs based on
        // `this._state` we need to ensure `page` is added based on its presence
        // in `this._state`, not `document.location.search`...
        //
        // `sort` is added here because it's never present in the facet_dict sent
        // from the backend...
        this.filterAndSortNames = getDimensionFieldNames().concat(['page', 'page_size', 'sort']);
        if (isCollection() || isShop()) {
            this.filterAndSortNames.push(FacetTagNames.QUERY);
        }

        this._state.setFilterAndSortNames(this.filterAndSortNames);

        this.facets.forEach(({ key }) => {
            this.filterAndSortNames.push(key);
        });
    }

    /**
     * @param {jQuery} $facet
     * @param {string} checkboxName
     * @param {string} checkboxValue
     * @param {boolean} isChecked
     */
    updateFacetCheckboxSelection($facet, checkboxName, checkboxValue, isChecked) {
        $facet.find(`input[name="${checkboxName}"][value="${checkboxValue}"]`).prop('checked', !!isChecked);
    }

    /**
     * @returns {Object}
     */
    getDirtyState() {
        const originalState = this._state.getState();
        this.updateStateFromSelections();
        const newState = this._state.getState();
        this._state.setStateObject(originalState);
        return newState;
    }

    /**
     * Updates the state object based on the form's current selections
     */
    updateStateFromSelections() {
        this.clearState();
        let isLocationRequired = false;
        this.$searchForm.find('.js-facet-input:checked').each((_, el) => {
            if (!isLocationRequired) {
                isLocationRequired = this.facetBuilder.isLocationRequired(el.value);
            }
            this.pushState(el.name, el.value, false);
        });

        const locationVal = this.getLocationValue();
        if (locationVal && isLocationRequired) {
            this.pushState(FacetTagNames.LOCATION, locationVal, false);
        }

        getCustomRangeFieldNames().forEach((fieldName) => {
            const $rangeContainer = this.$searchForm.find(`[data-key="${fieldName}"]`);
            const rangeValue = getCustomRangeValue($rangeContainer);
            if (rangeValue) {
                this.pushState(fieldName, rangeValue, false);
            }
        });

        const sort = this.$searchForm.find('[name="sort"]')[0];
        this.pushState(sort.name, sort.value, false);
    }

    /**
     * @param {string} fieldName The name of the field to check
     * @returns {boolean} True indicates the field name provided has a custom range component
     */
    isFieldCustomRange(fieldName) {
        return getCustomRangeFieldNames().includes(fieldName);
    }

    /**
     * @param {string} key
     * @returns {string|Array|undefined} The state value for the provided key
     */
    getStateVal(key) {
        return this._state.getStateVal(key);
    }

    /*
     * Helper function for determining if the key/value pair exists in state.
     *
     * @param {string} key
     * @param {string} value
     * @returns {boolean} Whether the key/value is in state.
     */
    hasState(key, value) {
        const state = this.getStateVal(key);
        return $.isArray(state) ? state.includes(value) : state === value;
    }

    /**
     * Whether any facets are selected within the given refinement or, if the refinements are omitted, the whole form.
     *
     * @param {jQuery=} $refinement
     * @returns {boolean}
     */
    hasSelections($refinement) {
        const stateObj = this.isDirty() ? this.getDirtyState() : this._state.getState();
        let selectionCount = 0;

        if ($refinement) {
            const refinementKey = $refinement.data('key');
            if (refinementKey === 'shipping_and_delivery') {
                selectionCount = [FacetTagNames.SHIPPING_OPTIONS]
                    .flatMap((key) => stateObj[key])
                    .filter((selection) => selection !== undefined).length;
            } else {
                let selections = stateObj[refinementKey] || [];
                if (!$.isArray(selections)) {
                    selections = [selections];
                }
                selectionCount = selections.length;
            }
        } else {
            Object.keys(stateObj).forEach((key) => {
                if (!FACET_KEYS_TO_SKIP.includes(key) && this.filterAndSortNames.includes(key)) {
                    selectionCount += 1;
                }
            });
        }

        return selectionCount > 0;
    }

    /**
     * @param {jQuery} $refinement
     */
    updateSelectionIndicator($refinement) {
        $refinement.toggleClass('has-selections', this.hasSelections($refinement));
    }
}

export default SearchView;
