import $ from 'jquery';

import ImageViewer from 'chairisher/view/imageviewer';
import NavigationView from 'chairisher/view/navigation';

import {
    SellerReviewsPosition,
    SellerReviewsSubType,
    trackSellerReviewsClickEvent,
    trackSellerReviewsSectionView,
} from 'chairisher/analytics/sellerreviews';
import {
    getReviewImageViewerIndicatorSrcsets,
    getReviewImageViewerSrcsets,
    getSellerReviewsUrl,
} from 'chairisher/context/sellerreviews';
import { getJSON } from 'chairisher/util/fetch';
import { observeIntersectionForElsOnce } from 'chairisher/view/helper/intersectionobserver';

const MAX_NUM_INITIAL_REVIEWS = 3;

/**
 * Binds the actions related to seller reviews.
 */
export default function bindSellerReviews() {
    const $el = $('.js-seller-reviews');

    const $dataEl = $('#seller-reviews');
    observeIntersectionForElsOnce($dataEl, async () => {
        trackSellerReviewsSectionView();

        const reviewsData = await getJSON(getSellerReviewsUrl());
        $dataEl.html(reviewsData.html);

        const isTruncated = (el) => el.scrollHeight > el.clientHeight;

        const clearNonTruncatedClasses = ($els) => {
            $els.each((_, content) => {
                if (!isTruncated(content)) {
                    $(content).removeClass('can-truncate is-truncated');
                }
            });
        };

        const showImageViewer = (currentTarget, position) => {
            new ImageViewer({
                $targetElementOnClose: $dataEl,
                imageSrcsets: getReviewImageViewerSrcsets(),
                indicatorSrcsets: getReviewImageViewerIndicatorSrcsets(),
                shouldIncludeFavoriteAction: false,
                startIndex: $(currentTarget).data('index') || 0,
            }).show();

            trackSellerReviewsClickEvent(SellerReviewsSubType.IMAGE_VIEWER_OPEN, position);
        };

        //
        // Seller Reviews - Card Actions
        //

        const $reviewsCards = $('#seller-reviews-cards');
        const $initialCards = $reviewsCards.find('.js-seller-reviews-cards-initial');
        clearNonTruncatedClasses($initialCards.find('.js-reviews-card-content'));

        $reviewsCards.on('click', '.js-reviews-card-content.can-truncate', ({ currentTarget }) => {
            $(currentTarget).toggleClass('is-truncated');
        });

        $reviewsCards.on('click', '.js-reviews-card-img-btn', ({ currentTarget }) => {
            showImageViewer(currentTarget, SellerReviewsPosition.CARD_IMAGE);
        });

        //
        // Seller Reviews - Details Actions
        //

        const $remainingCards = $reviewsCards.find('.js-seller-reviews-cards-remaining');
        const $viewAllReviewsBtnLink = $el.find('.js-view-all-reviews-btn-link');
        $viewAllReviewsBtnLink.on('click', ({ currentTarget }) => {
            const shouldExpand = $remainingCards.hasClass('hidden');

            $remainingCards.toggleClass('hidden');

            const $btnLink = $(currentTarget);
            if (shouldExpand) {
                trackSellerReviewsClickEvent(
                    SellerReviewsSubType.VIEW_ALL_REVIEWS,
                    SellerReviewsPosition.VIEW_ALL_REVIEWS,
                );
                clearNonTruncatedClasses($remainingCards.find('.js-reviews-card-content'));
                $btnLink.text('View Less');
            } else {
                $btnLink.text('Read More Reviews');
                NavigationView.scrollToElement($dataEl);
            }
        });

        //
        // Seller Reviews - Photos Actions
        //

        const $reviewsPhotos = $el.find('.js-seller-reviews-photos');
        $reviewsPhotos.find('.js-reviews-photos-img-btn').on('click', ({ currentTarget }) => {
            showImageViewer(currentTarget, SellerReviewsPosition.PHOTOS_IMAGE);
        });

        $reviewsPhotos.find('.js-view-all-photos-link').on('click', ({ currentTarget }) => {
            showImageViewer(currentTarget, SellerReviewsPosition.VIEW_ALL_PHOTOS);
        });

        //
        // Seller Reviews - Ratings Actions
        //

        const $reviewsRatingBtn = $el.find('.js-seller-reviews-rating-btn');
        $reviewsRatingBtn.on('click', async ({ currentTarget }) => {
            $reviewsRatingBtn.removeClass('selected');
            const activeRating = $(currentTarget).addClass('selected').data('rating');
            const response = await getJSON(`${getSellerReviewsUrl()}?rating=${activeRating}`);
            const cardsHtml = response.cards_html;

            $initialCards.html(cardsHtml.slice(0, MAX_NUM_INITIAL_REVIEWS));
            $remainingCards.html(cardsHtml.slice(MAX_NUM_INITIAL_REVIEWS));
            $viewAllReviewsBtnLink.toggleClass('hidden', cardsHtml.length <= MAX_NUM_INITIAL_REVIEWS);

            clearNonTruncatedClasses($reviewsCards.find('.js-reviews-card-content'));
        });
    });
}
