import $ from 'jquery';

import FavoriteProductView from 'chairisher/view/product/favorite';
import ProductModel from 'chairisher/model/product';

import { getNumProductReviews, getProductObject, getProductRating } from 'chairisher/context/product';
import { getNumSellerReviews, getSellerRating } from 'chairisher/context/sellerreviews';
import { AmplitudeEventProperty, logAmplitudeClickEvent, logAmplitudeEvent } from 'chairisher/util/analytics';

const CTA_TYPE = 'seller reviews';

/**
 * @enum {string}
 */
export const SellerReviewsPosition = {
    CARD_IMAGE: 'card image',
    PHOTOS_IMAGE: 'photos image',
    VIEW_ALL_PHOTOS: 'view all photos',
    VIEW_ALL_REVIEWS: 'view all reviews',
};

/**
 * @enum {string}
 */
export const SellerReviewsSubType = {
    IMAGE_VIEWER_OPEN: 'image viewer - open',
    RATING_STARS: 'rating stars',
    VIEW_ALL_REVIEWS: 'view all reviews',
};

/**
 * Tracks a 'click' event for seller reviews
 *
 * @param {string} subtype The subtype of the element that was clicked
 * @param {string} position The position at which the element click is tracked
 * @param {string=} url Optional url of the element that was clicked
 */
export function trackSellerReviewsClickEvent(subtype, position, url = null) {
    logAmplitudeClickEvent(`${CTA_TYPE} - ${subtype}`, position, url, {
        [AmplitudeEventProperty.SELLER_RATING]: getSellerRating(),
    });
}

/**
 * Tracks when the seller reviews section is viewed
 */
export function trackSellerReviewsSectionView() {
    const eventProperties = {
        [AmplitudeEventProperty.NUM_SELLER_REVIEWS]: getNumSellerReviews(),
        [AmplitudeEventProperty.SELLER_RATING]: getSellerRating(),
    };

    const product = getProductObject();
    if (!$.isEmptyObject(product)) {
        const productModel = new ProductModel(product);
        const productId = productModel.getId();
        const isFavoritedByViewer = FavoriteProductView.isProductIdFavorited(productId);

        eventProperties[AmplitudeEventProperty.IS_FAVORITED_BY_VIEWER] = isFavoritedByViewer;
        eventProperties[AmplitudeEventProperty.IS_MULTIPLE] = productModel.getIsMultiple();
        eventProperties[AmplitudeEventProperty.IS_NEWLY_MADE] = productModel.getIsNewlyMade();
        eventProperties[AmplitudeEventProperty.IS_WHOLESALE] = productModel.getIsWholesale();
        eventProperties[AmplitudeEventProperty.NUM_PRODUCT_REVIEWS] = getNumProductReviews();
        eventProperties[AmplitudeEventProperty.PRODUCT_ID] = productId;
        eventProperties[AmplitudeEventProperty.PRODUCT_RATING] = getProductRating();
    }

    logAmplitudeEvent(`${CTA_TYPE} - view`, eventProperties);
}
