const ChairishContext = window.chairisher.context;

/**
 * @return {string} URL of view which lists all images for a business
 */
export function getBusinessImageListUrl() {
    return ChairishContext.BUSINESS_IMAGE_LIST_URL;
}

/**
 * List of specializations for this profile, if any.
 *
 * @return {Array.<string>}
 */
export function getBusinessSpecializations() {
    return ChairishContext.BUSINESS_SPECIALIZATIONS || [];
}

/**
 * @returns {boolean} Whether the user has set a vacation period
 */
export function getHasVacationSet() {
    return ChairishContext.HAS_VACATION_SET;
}

/**
 * @returns {string} URL of view which toggles vacation mode
 */
export function getVacationToggleUrl() {
    return ChairishContext.VACATION_TOGGLE_URL;
}

/**
 * @param {boolean} hasVacationSet: Whether the user has set a vacation period
 */
export function setHasVacationSet(hasVacationSet) {
    ChairishContext.HAS_VACATION_SET = !!hasVacationSet;
}
