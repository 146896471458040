import $ from 'jquery';
import 'chairisher/view/helper/jquery';

const DEFAULT_ANIMATION_SPEED_IN_MS = 400;

/**
 * @param {Object} settings
 * @param {jQuery} settings.$carousel A jQuery instance of the entire carousel object
 * @param {jQuery=} settings.$carouselIndicators A jQuery instance of the carousel's indicators, if any
 */
class Carousel {
    constructor({ $carousel, $carouselIndicators = null }) {
        this.$carousel = $carousel;
        this.$carouselIndicators = $carouselIndicators;
        this.$carouselInner = this.$carousel.find('.js-carousel-inner');
        this.$carouselSlides = this.$carousel.find('.js-carousel-slide');
        this.carouselIndicatorCoordinates = [];
        this.carouselIndicatorSelector = '.js-carousel-indicator';
        this.carouselSlideCoordinates = [];
        this.currentIndex = 0;
        this.eventNamespace = 'chairishcarousel';
        this.maxIndex = this.$carouselSlides.length - 1;
        this.shouldSuppressEvent = false;

        this.updateSlideCoordinates();
        this.updateProgressClasses();
    }

    /**
     * Binds all necessary events to make the carousel work
     */
    bind() {
        // update the current index when the carousel slides
        this.$carouselInner.smartscroll(({ currentTarget }) => {
            const newIndex = this.getSlideIndexForCoord(currentTarget.scrollLeft);
            if (newIndex !== null && newIndex !== this.currentIndex) {
                this.setCurrentIndex(newIndex);

                if (this.$carouselIndicators !== null) {
                    this.changeIndicator({ newIndex });
                }
            }
        }, this.eventNamespace);

        // update the slide coordinates whenever the viewport changes
        $(window).smartresize(() => {
            this.updateSlideCoordinates();
            this.changeSlide({ newIndex: this.currentIndex });
        }, this.eventNamespace);

        // display the previous or next slide when a directional element is clicked
        this.$carousel.find(['.js-next', '.js-previous'].join(',')).on('click', (e) => {
            e.preventDefault();
            const slideIndex = this.currentIndex + ($(e.currentTarget).hasClass('js-previous') ? -1 : 1);

            this.changeSlide({ newIndex: slideIndex });
        });

        this.$carousel.on('slidechange', ({ index }) => {
            // remove .selected from all carousel indicators
            const $indicators = this.$carousel.find(this.carouselIndicatorSelector);
            $indicators.removeClass('selected');

            // set the newly selected carousel indicator
            $indicators.eq(index).addClass('selected');
        });

        this.$carousel.on('click', this.carouselIndicatorSelector, (e) => {
            e.preventDefault();

            // remove .selected from all carousel indicators
            $(this.carouselIndicatorSelector).removeClass('selected');

            // set the newly selected carousel indicator
            const $indicator = $(e.currentTarget);
            $indicator.addClass('selected');

            // display the appropriate slide
            this.changeSlide({ newIndex: $indicator.data('index'), shouldSuppressEvent: true });
        });
    }

    /**
     * Displays an indicator at a given index
     *
     * @param {number} newIndex
     * @param {number=} animationSpeedInMs
     */
    changeIndicator({ newIndex, animationSpeedInMs = DEFAULT_ANIMATION_SPEED_IN_MS }) {
        this.changeSelectedSlide({
            $el: this.$carouselIndicators,
            newIndex,
            coords: this.carouselIndicatorCoordinates,
            shouldSuppressEvent: true,
            animationSpeedInMs,
        });
    }

    /**
     * @param {jQuery} $el the jQuery element whose slide should be selected
     * @param {number} newIndex the 1-based number of the selected index
     * @param {Array.<Array<number>>} coords The coordinates of $el's slides
     * @param {boolean} shouldSuppressEvent True indicates the the `slidechange` event should be suppressed
     * @param {number} animationSpeedInMs The speed in ms by which the slide animation should change
     */
    changeSelectedSlide({ $el, newIndex, coords, shouldSuppressEvent, animationSpeedInMs }) {
        if (newIndex >= 0 && newIndex <= coords.length - 1) {
            const minCoord = coords[newIndex][0];
            const newLeft = newIndex === this.maxIndex ? minCoord + 1 : minCoord; // shifts last slide by 1px to prevent part of previous slide from being seen
            this.shouldSuppressEvent = shouldSuppressEvent;

            $el.stop().animate({ scrollLeft: newLeft }, animationSpeedInMs, () => {
                this.shouldSuppressEvent = false;
            });
        }
    }

    /**
     * Displays a slide at a given index at the carousel's coordinate (0, 0)
     *
     * @param {number} newIndex
     * @param {boolean=} shouldSuppressEvent
     * @param {number=} animationSpeedInMs
     */
    changeSlide({ newIndex, shouldSuppressEvent = false, animationSpeedInMs = DEFAULT_ANIMATION_SPEED_IN_MS }) {
        this.changeSelectedSlide({
            $el: this.$carouselInner,
            newIndex,
            coords: this.carouselSlideCoordinates,
            shouldSuppressEvent,
            animationSpeedInMs,
        });
    }

    /**
     * @param {number} xCoord The x coordinate of the element whose index should be returned
     * @returns {number|null} The index of the element at the given x coordinate, if it exists
     */
    getSlideIndexForCoord(xCoord) {
        const xCoordInt = Math.ceil(xCoord);
        for (let i = 0; i < this.carouselSlideCoordinates.length; i += 1) {
            const coord = this.carouselSlideCoordinates[i];
            if (xCoordInt >= coord[0] && xCoordInt <= coord[1]) {
                return i;
            }
        }
        return null;
    }

    /**
     * Sets the new index displayed at the carousel's coordinate (0, 0) and triggers a `slidechange` event
     *
     * @param {number} newIndex The new index displayed at the carousel's coordinate (0, 0)
     */
    setCurrentIndex(newIndex) {
        const previousIndex = this.currentIndex;
        this.currentIndex = newIndex;

        this.updateProgressClasses();
        if (!this.shouldSuppressEvent) {
            this.triggerSlideChangeEvent(previousIndex);
        }
    }

    /**
     * Triggers a `slidechange` event
     *
     * @param {number} previousIndex The previous slide index before the carousel changed
     */
    triggerSlideChangeEvent(previousIndex) {
        const e = $.Event('slidechange', {
            index: this.currentIndex,
            previousIndex,
        });

        this.$carousel.trigger(e);
    }

    /**
     * Updates the presence of CSS utility classes that indicate if the carousel is at the beginning or end
     */
    updateProgressClasses() {
        let progressClass = '';

        if (this.currentIndex === 0) {
            progressClass = 'carousel-beginning';
        } else if (this.currentIndex === this.carouselSlideCoordinates.length - 1) {
            progressClass = 'carousel-end';
        }

        this.$carousel.removeClass('carousel-beginning carousel-end').addClass(progressClass);
    }

    /**
     * Updates the internal collection of slide coordinates
     */
    updateSlideCoordinates() {
        const generateCoords = ($slides) => {
            const coords = [];
            let width = 0;

            $.each($slides, (i) => {
                const start = width;
                width += $slides.eq(i).width();

                coords.push([start, width - 1]); // subtract one since we're zero-based
            });

            return coords;
        };

        this.carouselSlideCoordinates = generateCoords(this.$carouselSlides);

        if (this.$carouselIndicators !== null) {
            this.carouselIndicatorCoordinates = generateCoords(
                this.$carouselIndicators.find(this.carouselIndicatorSelector),
            );
        }
    }
}

export default Carousel;
