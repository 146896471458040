import $ from 'jquery';

import Carousel from 'chairisher/component/carousel';
import KeyUtils from 'chairisher/util/key';
import ModalFactory from 'chairisher/factory/modal';
import ViewportUtil from 'chairisher/util/viewport';

import favoriteProductViewInstance from 'chairisher/view/product/favorite';

import { getProductTitleTruncated } from 'chairisher/context/product';
import { isMaxTablet } from 'chairisher/util/mediaquery';

let hasZoomMessagingBeenDisplayed = false;
const carouselTemplateSelector = '#js-template-carousel';
const eventNamespace = 'imageviewer';
const favoriteActionTemplateSelector = '.js-favorite-action';

/**
 * @param {Object=} options
 * @constructor
 */
export default class ImageViewer {
    constructor({
        $targetElementOnClose = null,
        favoriteTrackingLabel = null,
        imageSrcsets = [],
        indicatorSrcsets = [],
        product = null,
        shouldIncludeFavoriteAction = true,
        startIndex = 0,
    }) {
        this.$targetElementOnClose = $targetElementOnClose;
        this.carouselMarkup = $(carouselTemplateSelector).html();
        this.favoriteActionMarkup = $(favoriteActionTemplateSelector)[0]?.outerHTML;
        this.favoriteTrackingLabel = favoriteTrackingLabel;
        this.imageSrcsets = imageSrcsets;
        this.indicatorSrcsets = indicatorSrcsets;
        this.product = product;
        this.shouldIncludeFavoriteAction = shouldIncludeFavoriteAction;
        this.startIndex = startIndex;

        this.$backdrop = null;
    }

    /**
     * Returns a jQuery object for the product favorite action
     *
     * @returns {jQuery}
     */
    getProductFavoriteAction() {
        const productId = this.product.getId();

        const $favoriteAction = $(this.favoriteActionMarkup);
        $favoriteAction.attr({
            'data-product-id': productId,
            'data-product-url': this.product.getWebUrl(),
            'data-product-price': this.product.getPrice(),
            'data-product-taxonomy': this.product.getTaxonomy(),
            'data-product-title': this.product.getTitle(),
            'data-dealer-guid': this.product.getDealerGuid(),
            'data-is-variant': this.product.getIsVariant(),
        });

        const $favoriteButton = $favoriteAction.find('.js-favorite-button');
        $favoriteButton.attr('data-tracking-label', this.favoriteTrackingLabel);

        const isFavorite = favoriteProductViewInstance.getProductIds().includes(productId);
        favoriteProductViewInstance.drawButton(isFavorite, productId, $favoriteAction.find('.js-favorite-button'));

        return $favoriteAction;
    }

    /**
     * Returns a collection of jQuery image elements
     *
     * @param {Array.<string>} imageSrcsets The URLs to turn into image elements
     * @returns {Array}
     */
    getImagesFromUrls(imageSrcsets) {
        return imageSrcsets.map((img) =>
            $('<img />', {
                src: img.src,
                srcset: img.srcset,
                sizes: img.sizes,
            }),
        );
    }

    /**
     * Constructs and returns a bound carousel using `this._imageUrls` and `this._indicatorImageUrls`
     *
     * @returns {jQuery} The carousel jQuery element
     */
    getCarousel() {
        const $indicatorImages = this.getImagesFromUrls(this.indicatorSrcsets);
        const $indicators = $indicatorImages.map(($indicatorImage, i) =>
            $('<li></li>', {
                'class': `carousel-indicator js-carousel-indicator${i === 0 ? ' selected' : ''}`,
                'data-index': i,
            }).append($indicatorImage),
        );

        const $slideImages = this.getImagesFromUrls(this.imageSrcsets);
        const $slides = $slideImages.map(($slideImage, i) =>
            $('<div></div>', {
                'class': 'carousel-slide js-carousel-slide',
                'data-index': i,
            }).append($slideImage),
        );

        // build product title
        const $productTitle = $('<span></span>', {
            class: 'product-title visible-lg',
        }).html(getProductTitleTruncated());

        // build the favorite action, if included
        const $favoriteAction = this.shouldIncludeFavoriteAction
            ? this.getProductFavoriteAction().addClass('visible-lg')
            : null;

        const $carousel = $(this.carouselMarkup);
        $carousel.addClass('carousel-viewer');
        $carousel.find('.js-carousel-inner').html($slides);
        $carousel.find('.js-carousel-indicators').html($indicators);
        $carousel.find('.js-carousel-indicators-wrapper').prepend($productTitle).append($favoriteAction);

        return $carousel;
    }

    /**
     * Returns an element with instructions if it has not yet been displayed
     *
     * @returns {jQuery|null}
     */
    getZoomInstructions() {
        let $el = null;

        if (isMaxTablet() && !hasZoomMessagingBeenDisplayed) {
            $el = $('<span></span>', {
                class: 'instructions',
                text: 'Pinch to Zoom',
            });
            hasZoomMessagingBeenDisplayed = true;
        }

        return $el;
    }

    /**
     * Hides and removes the images viewer from the DOM
     */
    hide() {
        ViewportUtil.makeNonScalable();
        $('html').removeClass('viewer-open');
        this.$backdrop.remove();
        $(document.body).off(`keyup.${eventNamespace}`);

        if (this.$targetElementOnClose?.length > 0) {
            window.scrollTo(0, this.$targetElementOnClose[0].offsetTop);
        }
    }

    /**
     * Displays the image viewer
     */
    show() {
        // build the backdrop
        this.$backdrop = ModalFactory.createModalBackdrop(true);
        this.$backdrop.addClass('image-viewer-wrapper');
        this.$backdrop.removeClass('in');
        this.$backdrop.append($('<span class="cicon cicon-x js-close"></span>'));

        // build the carousel
        const $carousel = this.getCarousel();
        $carousel.addClass('fade');

        const $zoomInstructions = this.getZoomInstructions();
        if ($zoomInstructions && $zoomInstructions.length) {
            $carousel.append($zoomInstructions);
            window.setTimeout(() => {
                $zoomInstructions.remove();
            }, 2000);
        }

        // add the backdrop and bind events to the body
        const $body = $(document.body);
        $body.append(this.$backdrop);

        $body.one(`keyup.${eventNamespace}`, (e) => {
            if (e.which === KeyUtils.KeyCodes.Escape) {
                this.hide();
            }
        });

        // bindings
        this.$backdrop.on('click', '.js-close', () => {
            this.hide();
        });

        this.$backdrop.on('click', favoriteProductViewInstance.getFavoriteButtonSelector(), (e) => {
            e.preventDefault();

            const productId = this.product.getId();

            favoriteProductViewInstance.setFavoriteTrackingLabel(this.favoriteTrackingLabel);
            favoriteProductViewInstance.toggleProductId(productId, undefined, this.product.getIsPurchasable());

            favoriteProductViewInstance.drawButton(
                favoriteProductViewInstance.isProductIdFavorited(productId),
                productId,
            );
            favoriteProductViewInstance.updateFavoriteCount();
            favoriteProductViewInstance.updateProductFavoriteCount(productId);

            if (favoriteProductViewInstance.isProductIdFavorited(productId)) {
                $('#js-nav-favorites a').popover('hide');
            }
        });

        const $html = $('html');
        $html.addClass('viewer-open');

        ViewportUtil.makeScalable();

        // wait for the next iteration of the event loop otherwise the fade in doesn't happen
        window.setTimeout(() => {
            this.$backdrop.addClass('opaque');
            this.$backdrop.append($carousel);

            const carousel = new Carousel({
                $carousel,
                $carouselIndicators: $carousel.find('.js-carousel-indicators'),
            });
            carousel.bind();
            carousel.changeSlide({ newIndex: this.startIndex, animationSpeedInMs: 0 });

            window.setTimeout(() => {
                $carousel.addClass('opaque');
            }, 250);
        }, 100);
    }
}
